<template>
  <div class="companies required-item consulting">
    <div class="container">
      <h2 class="title-profile mt-0">{{ $route.name }}</h2>
      <p>{{ $t("TextExample") }}</p>
      <div class="row">
        <div class="head-section-profile justify-content-start">
          <div class="search">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.92 13.92L17 17M16.111 8.581C16.111 12.768 12.728 16.162 8.556 16.162C4.383 16.162 1 12.768 1 8.582C1 4.393 4.383 1 8.555 1C12.728 1 16.111 4.394 16.111 8.581Z"
                stroke="#225476"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <input
              type="text"
              v-model="searchQuery"
              placeholder="ابحث باسم الاستشارة..."
            />
          </div>
          <div>
            <select name="" id="" class="mb-0">
              <option value="">
                {{
                  this.$i18n.locale == "ar" ? "التصنيف حسب..." : "Filter By..."
                }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-if="filteredEmployees.length > 0"
          class="col-md-4"
          v-for="item in filteredEmployees"
          :key="item.id"
        >
          <div
            class="card-role"
            style="box-shadow: 0px 5px 30px 0px rgba(10, 48, 65, 0.1)"
          >
            <div class="d-flex align-items-center">
              <div class="icon">
                <svg
                  width="18"
                  height="22"
                  viewBox="0 0 18 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.25 5.75C14.25 7.14239 13.6969 8.47774 12.7123 9.46231C11.7277 10.4469 10.3924 11 9 11C7.60761 11 6.27226 10.4469 5.28769 9.46231C4.30312 8.47774 3.75 7.14239 3.75 5.75C3.75 4.35761 4.30312 3.02226 5.28769 2.03769C6.27226 1.05312 7.60761 0.5 9 0.5C10.3924 0.5 11.7277 1.05312 12.7123 2.03769C13.6969 3.02226 14.25 4.35761 14.25 5.75ZM12.75 5.75C12.75 4.75544 12.3549 3.80161 11.6517 3.09835C10.9484 2.39509 9.99456 2 9 2C8.00544 2 7.05161 2.39509 6.34835 3.09835C5.64509 3.80161 5.25 4.75544 5.25 5.75C5.25 6.74456 5.64509 7.69839 6.34835 8.40165C7.05161 9.10491 8.00544 9.5 9 9.5C9.99456 9.5 10.9484 9.10491 11.6517 8.40165C12.3549 7.69839 12.75 6.74456 12.75 5.75ZM2.625 12.5C1.92881 12.5 1.26113 12.7766 0.768845 13.2688C0.276562 13.7611 0 14.4288 0 15.125V15.5C0 17.2947 1.14225 18.8127 2.76375 19.8447C4.39425 20.8827 6.6015 21.5 9 21.5C11.3985 21.5 13.605 20.8827 15.2363 19.8447C16.8578 18.8127 18 17.2947 18 15.5V15.125C18 14.4288 17.7234 13.7611 17.2312 13.2688C16.7389 12.7766 16.0712 12.5 15.375 12.5H2.625ZM1.5 15.125C1.5 14.8266 1.61853 14.5405 1.8295 14.3295C2.04048 14.1185 2.32663 14 2.625 14H15.375C15.6734 14 15.9595 14.1185 16.1705 14.3295C16.3815 14.5405 16.5 14.8266 16.5 15.125V15.5C16.5 16.6048 15.795 17.7118 14.4307 18.5795C13.0755 19.442 11.1578 20 9 20C6.84225 20 4.9245 19.442 3.56925 18.5795C2.20425 17.7125 1.5 16.604 1.5 15.5V15.125Z"
                    fill="#225476"
                  />
                </svg>
              </div>
              <div>
                <div class="name">{{ item.name }}</div>
                <div class="role-name">
                  {{
                    lang == "en"
                      ? item.role?.show_name.en
                      : item.role?.show_name.ar
                  }}
                </div>
              </div>
            </div>
            <div class="powers">
              {{ $t("The Powers") }}
            </div>
            <div class="power-list">
              <span v-for="j of item.role?.permissions">{{
                lang == "en" ? j.show_name.en : j.show_name.ar
              }}</span>
            </div>
            <div class="power-count">
              <div class="count">
                {{ item.role?.permissions.length }} {{ $t("Powers") }}
              </div>
              <button class="btn-main b-20" @click="togglePowerUpdate(item)">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61111 0.5C6.76687 0.500173 6.91669 0.559815 7.02995 0.666741C7.14321 0.773666 7.21137 0.919805 7.22049 1.0753C7.22962 1.23079 7.17903 1.3839 7.07906 1.50334C6.97909 1.62279 6.83728 1.69955 6.68261 1.71794L6.61111 1.72222H1.72222V10.2778H10.2778V5.38889C10.278 5.23313 10.3376 5.08331 10.4445 4.97005C10.5514 4.85679 10.6976 4.78863 10.8531 4.77951C11.0086 4.77038 11.1617 4.82097 11.2811 4.92094C11.4006 5.02091 11.4773 5.16272 11.4957 5.31739L11.5 5.38889V10.2778C11.5001 10.5861 11.3836 10.8831 11.174 11.1092C10.9643 11.3353 10.6852 11.4722 10.3889 11.4722H1.61111C1.30483 11.4722 1.02545 11.3355 0.805016 11.1097C0.584574 10.8838 0.468243 10.5861 0.468222 10.2778V1.72222C0.468222 1.4139 0.584574 1.1162 0.805016 0.890467C1.02545 0.664745 1.30483 0.527958 1.61111 0.527778H6.61111Z"
                    fill="#225476"
                  />
                </svg>
                <span>{{ $t("Edit") }}</span>
              </button>
            </div>
          </div>
        </div>
        <NoDataToShow v-else />
      </div>
    </div>
    <PowerUpdate
      :EmployeeObj="EmployeeObj"
      v-if="ShowPowerUpdate"
      @hide="togglePowerUpdate({})"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import axios from "axios";
import { notify } from "@kyvg/vue3-notification";
import Cookie from "cookie-universal";
const cookie = Cookie();

export default {
  data() {
    return {
      ShowPowerUpdate: false,
      Employees: [],
      EmployeeObj: {},
      searchQuery: "",
      lang: cookie.get("lang"),
      loading: false,
    };
  },
  computed: {
    filteredEmployees() {
      return this.Employees.filter((employee) =>
        employee.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  components: {
    PowerUpdate: defineAsyncComponent(() =>
      import("@/components/Profile/Companies/Modals/PowerUpdate.vue")
    ),
    NoDataToShow: defineAsyncComponent(() =>
      import("@/components/Global/NoDataToShow.vue")
    ),
  },
  methods: {
    togglePowerUpdate(item) {
      this.EmployeeObj = item;
      this.ShowPowerUpdate = !this.ShowPowerUpdate;
    },
    GetEmployees() {
      this.loading = true;
      axios
        .get(`/company/dashboard/employees`, {
          headers: {
            Authorization: `Bearer ${cookie.get("CompanyToken")}`,
          },
        })
        .then((res) => {
          this.Employees = res.data.data.User;
        })
        .catch((error) => {
          notify({
            type: "error",
            title: "خطأ",
            text:
              error.response?.data?.message || "حدث خطأ أثناء جلب الموظفين.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.GetEmployees();
  },
};
</script>
